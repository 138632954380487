import React, {useEffect, useRef, createRef} from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styles from './hero.module.scss'
import SVGAlaska from '../../images/alaska.svg'
import SVGGrant from '../../images/grant.svg'
import gsap from 'gsap'


const Scholarships2024 = (props) => {
  const refGrant = useRef([])
  const refGrants = useRef()
  const grantPos = []
  for (let i = 0; i < 12; i++) {
    refGrant.current[i] = createRef()
  }
  grantPos.push(
    ['18%', '17%'],
    ['35%', '0%'],
    ['15%', '33%'],
    ['50%', '7%'],
    ['49%', '36%'],
    ['44%', '59%'],
    ['30%', '45%'],
    ['63%', '28%'],
    ['81%', '67%'],
    ['35%', '24%'],
    ['53%', '50%'],
    ['33%', '71%'],
  )
  console.log('grantPos', grantPos)

  useEffect(() => {
    //Grants
    const tg = gsap.timeline()
    for (let i = 0; i < refGrant.current.length; i++) {
      // Drop
      // tg.set(refGrant.current[i].current, {opacity: 0.4})
      tg.to(refGrant.current[i].current, {
        left: grantPos[i][0],
        top: grantPos[i][1],
        scale: 1,
        opacity: 1,
        ease: 'back.out',
        duration: 0.6,
        delay: i > 0 ? -0.5 : 0
      })
    }
    // Pulsing
    tg.fromTo('.grant', {
      y: "+=0",
      scale: 1
    },
    {
      scale: 1.15,
      y: "-=2",
      duration: 2,
      stagger: {
        yoyo: true,
        repeat: -1,
        each: 1,
        ease: 'none',
      },
      delay: -6
    })
  }, [])

  return (
    <StaticQuery 
    query={graphql`
      query heroScholarshipsQuery2024 {
        wordpressPage(wordpress_id: {eq: 1184}) {
          acf {
            scholarships_title
            scholarships_blurb
            scholarships_button {
              title
              url
              target
            }
            scholarships_button {
              url
              target
            }
          }
        }
      }           
    `}
    render={data => {
      return (
        <div className={styles.scholarships}>
          <div className={styles.left}>
            {
              <>
                <h2>{data.wordpressPage.acf.scholarships_title}</h2>
                <p>{data.wordpressPage.acf.scholarships_blurb}</p>
                <Link to={data.wordpressPage.acf.scholarships_button.url} target={data.wordpressPage.acf.scholarships_button.target}>
                  <a className={styles.heroButton}>
                    {data.wordpressPage.acf.scholarships_button.title}
                  </a>
                </Link>
              </>
            }
          </div>
            <div className={styles.right} >
              <div className="alaskaGrants2024" ref={refGrants}>
                <img className="alaskaMap" src={SVGAlaska} />
                {refGrant.current.map((grant, i) => {
                  return (
                    <img key={`grant-img-key-${i}`} ref={refGrant.current[i]} className="grant" src={SVGGrant} />
                  )
                })}
              </div>
            </div>
        </div>
      )
    }}
  />
  )
}

export default Scholarships2024

