import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"
import styles from './hero.module.scss'
import Tilt from './tilt'

/* let images = [
  { src: gatsbyico, alt: "An icon", },
  { src: gatsbyman, alt: "An astronaut", },
  { src: gatsbyico, alt: "An icon", },
  { src: gatsbyman, alt: "An astronaut", },
  { src: gatsbyico, alt: "An icon", },
  { src: gatsbyman, alt: "An astronaut", },
] */

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
);

const Scholarships = (props) => {
  return (
    <StaticQuery 
    query={graphql`
      query heroScholarshipsQuery {
        wordpressPage(wordpress_id: {eq: 10}) {
          acf {
            scholarships_title
            scholarships_blurb
            scholarships_button {
              title
              url
              target
            }
            scholarships_images {
              scholarships_image {
                alt_text
                source_url
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            scholarships_button {
              url
              target
            }
          }
        }
      }           
    `}
    render={data => {
      const imagesChunked = chunk(data.wordpressPage.acf.scholarships_images, 3)
      return (
        <div className={styles.scholarships}>
          <div className={styles.left}>
            {
              <>
                <h2>{data.wordpressPage.acf.scholarships_title}</h2>
                <p>{data.wordpressPage.acf.scholarships_blurb}</p>
                <a className={styles.heroButton} href={data.wordpressPage.acf.scholarships_button.url} target={data.wordpressPage.acf.scholarships_button.target}>
                  {data.wordpressPage.acf.scholarships_button.title}
                </a>
              </>
            }
          </div>
          <Tilt>
            <div className={styles.right} >
              <div className={styles.pictureGrid} id="pictureGrid">
                {
                  imagesChunked.map((parent, z) => (
                    <div key={`pic-grid-container-${z}`}>
                      {
                        parent.map((img, i) => (
                          <Link key={`$pic-grid-img-link${i}`} to="/scholarships">
                            <Img
                              fluid={img.scholarships_image.localFile.childImageSharp.fluid}
                              key={img.scholarships_image.source_url}
                              alt={img.scholarships_image.alt_text}
                              className={styles.gImgWrapper}
                            />
                          </Link>
                        ))
                      }
                    </div>
                  )) 
                }
              </div>
            </div>
          </Tilt>
        </div>
      )
    }}
  />
  )
}

export default Scholarships

