import React, { useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import styles from './hero.module.scss'
import Pillars from './pillars'
import Container from '../container'
import Scholarships from './scholarships'
import Scholarships2024 from './scholarships-2024'
import Partners from './partners'
import Borealis from './borealis'
import StarField from '../header/starfield'
import ScrollMagic from 'scrollmagic'
import SVGIconScholarships from '../../images/icon-scholarships.svg'
import SVGIconCoins from '../../images/icon-coins.svg'
import SVGIconGrants from '../../images/icon-grants.svg'

const renderHero = (mode, title, content, link, linkTitle, target, box1, box2, box3) => {
    if ( mode === 'interactive' ) {
        return (
            <>
                <StarField />
                <Container isHero>
                    <Scholarships />
                    <Partners />
                    <Borealis />
                </Container>
                <div className={styles.pillars}>
                    <Pillars />
                </div>
                <div className={styles.pioneer}></div>
            </>
        )
        } else if ( mode === 'interactive2024' ) {
        return (
            <>
                <StarField />
                <Container isHero>
                    {/* <Scholarships2024 /> */}
                    {/* <Partners /> */}
                    <Borealis />
                </Container>
                <div className={styles.pillars}>
                    <Pillars />
                </div>
                <div className={styles.pioneer}></div>
            </>
        )
    } else if (mode === 'blog') {
        return (
            <Container isHero>
                <div className={styles.blog}>
                    <h1>{title}</h1>
                    <p>{content}</p>
                </div>
            </Container>
        )
    } else if (mode === 'contact') {
        return (
            <Container isHero>
                <div dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <form method="POST" data-netlify="true" name="Contact" data-netlify-honeypot="bot-field" action="#">
                    <input type="hidden" name="form-name" value="Contact" />
                    <input type="hidden" name="bot-field" />
                <div className={styles.formContainer}>
                    <div className={styles.details}>
                        <label htmlFor="name">Name*</label>
                        <input type="text" name="name" id="name" placeholder="Jane" required onFocus={(e) => { e.target.placeholder = '' }} onBlur={(e) => { e.target.placeholder = 'Jane' }} />
                        <label htmlFor="phone">Phone</label>
                        <input type="tel" name="phone" id="phone" placeholder="907-555-1234" onFocus={(e) => { e.target.placeholder = '' }} onBlur={(e) => { e.target.placeholder = '907-555-1234' }} />
                        <label htmlFor="email">Email*</label>
                        <input type="email" name="email" id="email" placeholder="jane@domain.com" required onFocus={(e) => { e.target.placeholder = '' }} onBlur={(e) => { e.target.placeholder = 'jane@domain.com' }} />
                    </div>
                    <div className={styles.message}>
                        <label htmlFor="message">Message*</label>
                        <textarea name="message" id="message" placeholder="My message..." required onFocus={(e) => { e.target.placeholder = '' }} onBlur={(e) => { e.target.placeholder = 'My message...' }} />
                        <input className="button" type="submit" name="submit" value="Submit" />
                    </div>
                </div>
                </form>
            </Container>
        )
    } else if (mode === 'eop') {
        return (
            <>
                <StarField />
                <Container isHero>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    <div className={styles.eopIcons}>
                        <div>
                            <img src={SVGIconScholarships} />
                            <h2>{box1.eop_hdr_amnt}</h2>
                            <h3>{box1.eop_hdr_title}</h3>
                            <p>{box1.eop_hdr_description}</p>
                            <a className="button" href={`#${box1.eop_hdr_anchor}`}>Learn more</a>
                        </div>
                        <div>
                            <img src={SVGIconCoins} />
                            <h2>{box2.eop_hdr_amnt}</h2>
                            <h3>{box2.eop_hdr_title}</h3>
                            <p>{box2.eop_hdr_description}</p>
                            <a className="button" href={`#${box2.eop_hdr_anchor}`}>Learn more</a>
                        </div>
                        <div>
                            <img src={SVGIconGrants} />
                            <h2>{box3.eop_hdr_amnt}</h2>
                            <h3>{box3.eop_hdr_title}</h3>
                            <p>{box3.eop_hdr_description}</p>
                            <a className="button" href={`#${box3.eop_hdr_anchor}`}>Learn more</a>
                        </div>
                    </div>
                    <div style={{color: 'white', textAlign: 'center', maxWidth: '800px', margin: '50px auto 0'}}>
                        <small>
                            About the numbers: Our scholarship portion is updated after each year’s program ends, and our grants and donation sections are updated annually – so be sure to check back in January 2025!
                        </small>
                    </div>
                </Container>
            </>
        )
    } else { // Default
        return (
            <Container isHero>
                <div dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {( link !== '' && linkTitle !== '' && link !== undefined ) &&
                    (
                        <div className={styles.buttonContainer}>
                            <a href={link} className="button buttonLarge" target={target ? target : ''}>{linkTitle}</a>
                        </div>
                    )
                }
            </Container>
        )
    }
}

const Hero = (props) => {
    useEffect(() => {
        let controller = new ScrollMagic.Controller()
        const burger = document.querySelector('.hamburger-inner')
        const navBar = document.getElementById('navBar')
        const heroST = document.getElementById('heroScrollTrigger')
        new ScrollMagic.Scene({
          triggerHook: 0,
          duration: 10,
          offset: 100,
        })
        /* new ScrollMagic.Scene({
          triggerHook: (navBar.offsetHeight / window.innerHeight),
          triggerElement: heroST,
          duration: heroST.offsetHeight,
          offset: 0
        }) */
        .on('start', function() {
            // console.log('start')
          navBar.classList.remove('opaque')
          burger.classList.remove('op')
        })
        .on('end', function() {
            // console.log('end');
          navBar.classList.add('opaque')
          burger.classList.add('op')
        })
        .addTo(controller)
    })
    return (
        <>
            { !props.image ?
                <div
                    className={`${styles.hero} ${ props.mode === 'default' || !props.mode ? styles.default : ''} ${props.mode === 'interactive' || props.mode === 'interactive2024' ? styles.interactive : ''} ${props.mode === 'contact' ? styles.contact : ''} ${props.mode === 'give' ? styles.give : ''} ${props.mode === 'eop' ? styles.eop : ''}`}
                    id="hero"
                >
                    {
                        renderHero(
                            props.mode,
                            props.title,
                            props.content,
                            props.button.url,
                            props.button.title,
                            props.button.target,
                            props.box1,
                            props.box2,
                            props.box3,
                        ) 
                    }
                    <div className={styles.heroScrollTrigger} id="heroScrollTrigger" />
                </div>
            :
                <BackgroundImage
                    className={`${styles.hero} ${ props.mode === 'default' || !props.mode ? styles.default : ''} ${props.mode === 'interactive' || props.mode === 'interactive2024' ? styles.interactive : ''} ${props.mode === 'contact' ? styles.contact : ''} ${props.mode === 'give' ? styles.give : ''} ${props.mode === 'eop' ? styles.eop : ''}`}
                    id="hero"
                    fluid={ props.image ? props.image : null }
                >
                    {
                        renderHero(
                            props.mode,
                            props.title,
                            props.content,
                            props.button.url,
                            props.button.title,
                            props.button.target,
                            props.box1,
                            props.box2,
                            props.box3,
                        ) 
                    }
                    <div className={styles.heroScrollTrigger} id="heroScrollTrigger" />
                </BackgroundImage>
            }
        </>
    )
}

export default Hero