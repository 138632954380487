import React, { useRef } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styles from './hero.module.scss'

const Borealis = () => {
  const tip1 = useRef(null)
  const tip2 = useRef(null)
  const tip3 = useRef(null)
  const tip4 = useRef(null)
  const tip5 = useRef(null)
  const tip6 = useRef(null)
  const toolTips = {
    s1: {
      title: 'Youth 19%',
      x: 900,
      y: 90,
    },
    s2: {
      title: 'Civic 17.9%',
      x: 860,
      y: 200, 
    },
    s3: {
      title: 'Senior 16%',
      x: 910,
      y: 310,
    },
    s4: {
      title: 'Health 14.9%',
      x: 750,
      y: 510,
    },
    s5: {
      title: 'Education 10.9%',
      x: 810,
      y: 620,
    },
    s6: {
      title: 'Sports 8.1%',
      x: 760,
      y: 720,
    },
  }
  const toggleToolTip = ((tip, status) => {
    status ? tip.current.classList.add('activeToolTip') : tip.current.classList.remove('activeToolTip')
  })

  return (
    <StaticQuery
      query={graphql`
        query effortsQuery {
          wordpressPage(wordpress_id: {eq: 10}) {
            acf {
              efforts_title
              efforts_blurb
              efforts_button {
                target
                title
                url
              }
            }
          }
          allWordpressWpArea {
            edges {
              node {
                name
                id
                description
                acf {
                  aof_featured
                  aof_percentage
                }
              }
            }
          }
        }
      `}
      render={data => {
        let featAreas = data.allWordpressWpArea.edges.filter((area) => {
          return area.node.acf.aof_featured ? true : false
        }).map((area) => {
          return area.node
        })
        return (
          <div className={styles.efforts}>
            <div className={styles.textBlock}>
                <h2>{ data.wordpressPage.acf.efforts_title }</h2>
                <p>{ data.wordpressPage.acf.efforts_blurb }</p>
                <a className={styles.heroButton} href={ data.wordpressPage.acf.efforts_button.url } target={ data.wordpressPage.acf.efforts_button.target }>{ data.wordpressPage.acf.efforts_button.title }</a>
            </div>
            <svg 
              className={styles.borealisSVG}
              viewBox="0 0 1200 1048.6"
              xmlns="http://www.w3.org/2000/svg" 
              preserveAspectRatio="xMaxYMax slice"
            >
              <linearGradient id="a" x1="26.288" x2="1584.3" y1="443.76" y2="443.76" gradientUnits="userSpaceOnUse">
                <stop stopColor="#3D94D0" offset="0"/>
                <stop stopColor="#3D94D0" offset=".5699"/>
                <stop stopColor="#3D94D0" stopOpacity="0" offset=".8387"/>
              </linearGradient>
              <linearGradient id="b" x1="26.288" x2="1584.3" y1="443.76" y2="443.76" gradientUnits="userSpaceOnUse">
                <stop stopColor="#92D8FF" offset="0"/>
                <stop stopColor="#92D8FF" offset=".5699"/>
                <stop stopColor="#92D8FF" stopOpacity="0" offset=".8441"/>
              </linearGradient>
              <path className={`${styles.shard} ${styles.bluShard} ${styles.bluThird}`} onMouseEnter={() => toggleToolTip(tip1, true)} onMouseLeave={() => toggleToolTip(tip1, false)} d="M26.3,702.9C237.6,847.5,778.5,81.4,1249.1,346.7c181.6,89,336.7,9.6,335.1-16.9   c-135.7,18.8-238.2-14.1-341.3-88.4C825.4-95.3,230.7,815,26.3,702.9z"/>
              <g className={`${styles.toolTipGroup} activeToolTip`} ref={tip1}>
                <rect width="300" height="60" x={toolTips.s1.x} y={toolTips.s1.y} className={styles.toolTipBox} />
        <text fontFamily="montSerrat" x={toolTips.s1.x + 20} y={toolTips.s1.y + 35} className={styles.toolTipText}>{featAreas[0].name} {featAreas[0].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s1.x + ' ' + (toolTips.s1.y + 60) + ' L' + toolTips.s1.x + ' ' + (toolTips.s1.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s1.x} cy={toolTips.s1.y + 145} className={styles.toolTipPoint} />
              </g>


              <path className={`${styles.shard} ${styles.bluShard} ${styles.bluSecond}`} onMouseEnter={() => toggleToolTip(tip2, true)} onMouseLeave={() => toggleToolTip(tip2, false)} d="M27.2,702.9c206.9,150.9,732.9-468.5,1229.5-265.7c138.3,47.7,255.3,28.9,327.5-107.4   c-67.6,39.7-200.8,72.7-330.5,1.9C783.1,48.1,228.2,821,27.2,702.9z"/>
              <g className={styles.toolTipGroup} ref={tip2}>
                <rect width="300" height="60" x={toolTips.s2.x} y={toolTips.s2.y} className={styles.toolTipBox} />
                <text fontFamily="montSerrat" x={toolTips.s2.x + 20} y={toolTips.s2.y + 35} className={styles.toolTipText}>{featAreas[1].name} {featAreas[1].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s2.x + ' ' + (toolTips.s2.y + 60) + ' L' + toolTips.s2.x + ' ' + (toolTips.s2.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s2.x} cy={toolTips.s2.y + 145} className={styles.toolTipPoint} />
              </g>

              <path className={`${styles.shard} ${styles.bluShard} ${styles.bluFirst}`} onMouseEnter={() => toggleToolTip(tip3, true)} onMouseLeave={() => toggleToolTip(tip3, false)} id="bluFirst" d="m28.1 702.9c202.5 156.7 608.5-338 1235-172.5 217.9 49.5 321.2-184.3 321.2-200.6-102.9 138.7-199.6 140.8-321.2 95.1-488-223.4-1037.5 401.8-1235 278z"/>
              <g className={styles.toolTipGroup} ref={tip3}>
                <rect width="300" height="60" x={toolTips.s3.x} y={toolTips.s3.y} className={styles.toolTipBox} />
                <text fontFamily="montSerrat" x={toolTips.s3.x + 20} y={toolTips.s3.y + 35} className={styles.toolTipText}>{featAreas[2].name} {featAreas[2].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s3.x + ' ' + (toolTips.s3.y + 60) + ' L' + toolTips.s3.x + ' ' + (toolTips.s3.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s3.x} cy={toolTips.s3.y + 145} className={styles.toolTipPoint} />
              </g>
              

              <linearGradient id="c" x1="-468.93" x2="1535" y1="736.07" y2="736.07" gradientUnits="userSpaceOnUse">
                <stop stopColor="#89C53F" stopOpacity="0" offset=".048387"/>
                <stop stopColor="#89C53F" offset=".3763"/>
                <stop stopColor="#89C53F" offset=".6989"/>
                <stop stopColor="#89C53F" stopOpacity="0" offset=".9624"/>
              </linearGradient>
              <linearGradient id="d" x1="-473.26" x2="1539" y1="730.02" y2="730.02" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D5FF99" stopOpacity="0" offset=".1129"/>
                <stop stopColor="#D5FF99" offset=".3602"/>
                <stop stopColor="#D5FF99" offset=".7043"/>
                <stop stopColor="#D5FF99" stopOpacity="0" offset=".9409"/>
              </linearGradient>
              <path className={`${styles.shard} ${styles.grnShard} ${styles.grnThird}`} onMouseEnter={() => toggleToolTip(tip4, true)} onMouseLeave={() => toggleToolTip(tip4, false)} id="grnThird" d="m1455.3 401.9c-24.2 63-86.4 117.6-187.9 167.4-141.8 69.5-328.1 10.4-580.5 58.4-401.1 76.3-507.6 296.2-838.8 313.8-1.1 0.1-311.7 8.1-312.9 8.2 0.6 3.2 6 31.2 6.5 33.8 0.7 0 311-8 311.8-8 303.8 2.1 439.1-153.3 707.7-215.5 283.6-65.7 447.4-3.7 621.6-10.6 115.8-4.6 191.1-72 329-77.3 38-3.7-93.3-231.2-56.5-270.2z"/>
              <g className={styles.toolTipGroup} ref={tip4}>
                <rect width="300" height="60" x={toolTips.s4.x} y={toolTips.s4.y} className={styles.toolTipBox} />
                <text fontFamily="montSerrat" x={toolTips.s4.x + 20} y={toolTips.s4.y + 35} className={styles.toolTipText}>{featAreas[3].name} {featAreas[3].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s4.x + ' ' + (toolTips.s4.y + 60) + ' L' + toolTips.s4.x + ' ' + (toolTips.s4.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s4.x} cy={toolTips.s4.y + 145} className={styles.toolTipPoint} />
              </g>
              
              <path className={`${styles.shard} ${styles.grnShard} ${styles.grnSecond}`} onMouseEnter={() => toggleToolTip(tip5, true)} onMouseLeave={() => toggleToolTip(tip5, false)} id="grnSecond" d="m1491 560c-29.2 60.9-95.6 110.2-200.8 151.6-147 57.7-327.9-16.3-583.3 11.1-405.9 43.5-530 254-861.5 244.7-1.1 0-311.4-17.2-312.5-17.3 0.4 3.2 3.5 31.6 3.8 34.2 0.7 0.1 310.6 17.3 311.4 17.3 302.6 26.7 450.1-117.1 722.9-157.3 288-42.4 446.2 32.7 620.4 39.9 115.8 4.8 196.3-56.3 334.1-50.3 38.1-0.6-74.3-238-34.5-273.9z"/>
              <g className={styles.toolTipGroup} ref={tip5}>
                <rect width="300" height="60" x={toolTips.s5.x} y={toolTips.s5.y} className={styles.toolTipBox} />
                <text fontFamily="montSerrat" x={toolTips.s5.x + 20} y={toolTips.s5.y + 35} className={styles.toolTipText}>{featAreas[4].name} {featAreas[4].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s5.x + ' ' + (toolTips.s5.y + 60) + ' L' + toolTips.s5.x + ' ' + (toolTips.s5.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s5.x} cy={toolTips.s5.y + 145} className={styles.toolTipPoint} />
              </g>
              
              <path className={`${styles.shard} ${styles.grnShard} ${styles.grnFirst}`} onMouseEnter={() => toggleToolTip(tip6, true)} onMouseLeave={() => toggleToolTip(tip6, false)} id="grnFirst" d="m1513.4 716.3c-34 58.4-104.1 102.3-212.2 135.2-151.1 45.9-325.6-42.2-582.4-35.1-408.1 11.2-548.4 211.2-878.1 175.7-1.1-0.1-309-41.8-310.1-41.9 0.1 3.2 1 31.8 1 34.4 0.7 0.1 308.3 41.8 309 41.9 299.5 50.6 458-81.1 733.1-99.6 290.5-19.5 442.2 67.9 615.3 88.9 115.1 14 200.2-40.6 337.1-23.7 38 2.4-55.3-243.2-12.7-275.8z"/>
              <g className={styles.toolTipGroup} ref={tip6}>
                <rect width="300" height="60" x={toolTips.s6.x} y={toolTips.s6.y} className={styles.toolTipBox} />
                <text fontFamily="montSerrat" x={toolTips.s6.x + 20} y={toolTips.s6.y + 35} className={styles.toolTipText}>{featAreas[5].name} {featAreas[5].acf.aof_percentage}%</text>
                <path d={ 'M' + toolTips.s6.x + ' ' + (toolTips.s6.y + 60) + ' L' + toolTips.s6.x + ' ' + (toolTips.s6.y + 140) } className={styles.toolTipPointer} />
                <circle r="5" cx={toolTips.s6.x} cy={toolTips.s6.y + 145} className={styles.toolTipPoint} />
              </g>
            </svg>

          </div>
        )
      }}
    />
  )
}

export default Borealis