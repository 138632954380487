import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import styles from './newsletter.module.scss'
import Container from '../container'

const Newsletter = (props) => {
  let { title, subtitle, image } = props
  return (
    <StaticQuery
      query={graphql`
      query newsletterQuery {
        wordpressAcfOptions {
          options {
            newsletter_component {
              newsletter_header
              newsletter_text
              newsletter_background {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                  }
                }
              }
            }
          }
        }
        `}
        render={data => {
          return (
            <BackgroundImage tag="div" className={styles.newsLetter} fluid={data.wordpressAcfOptions.options.newsletter_component.newsletter_background.localFile.childImageSharp.fluid} style={{zIndex: -1}}>
              <Container>
                <h2>{data.wordpressAcfOptions.options.newsletter_component.newsletter_header}</h2>
                <p>{data.wordpressAcfOptions.options.newsletter_component.newsletter_text}</p>
                <div className={styles.signUpForm}>
                  <form method="POST" data-netlify="true" name="Newsletter" data-netlify-honeypot="bot-field" action="#">
                    <input type="hidden" name="form-name" value="Newsletter" />
                    <input type="hidden" name="bot-field" />
                    <input type="email" name="email" placeholder="youremail@domain.com" onFocus={(e) => { e.target.placeholder = '' }} onBlur={(e) => { e.target.placeholder = 'youremail@domain.com' }} />
                    <input type="submit" value="Submit" />
                  </form>
                </div>
              </Container>
            </BackgroundImage>
          )
        }}
    />
  )
}

export default Newsletter