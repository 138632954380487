import React, { useEffect, useRef } from 'react'
import { graphql, StaticQuery, Link } from "gatsby"
import styles from './hero.module.scss'
import { gsap } from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import ScrollMagic from 'scrollmagic'

gsap.registerPlugin( MotionPathPlugin )

const Donors = (props) => {
  let { title, tier, right } = props;
  return (
    <div className={`aDonor ${styles.donor}`}>
      <div className={styles.donorInfo} style={{ right: right && '140%', left: right && 'unset' }}>
        <strong>{ title }</strong><br />
        <span>{ tier }</span>
      </div>
    </div>
  )
}


const Partners = (props) => {
  const donorPath = useRef()

  useEffect(() => {
    const allDonors = document.querySelectorAll('.aDonor')
    const milkyWay = document.getElementById('donationPathSVG')
    
    const scatterDonors = () => {
      // Distribute on X axis
      milkyWay.setAttribute('preserveAspectRatio', (window.innerWidth < 800) ? 'xMidYMin meet' : 'xMidYMin slice')
      gsap.set(
        allDonors, {
          motionPath: {
            path: donorPath.current,
            align: donorPath.current,
            start: 0,
            end: gsap.utils.distribute({
              base:0,
              amount: .95,
            }),
            from: 'center',
          }
        }
        )
        
        // Scatter Y axis
        for (let i = 0; i < allDonors.length; i++) { 
          gsap.set(allDonors[i], {
            y: "+=" + (Math.floor(Math.random() * -300) + 150),
          }
          );
        allDonors[i].addEventListener('mouseenter', (e) => {
          e.target.classList.add('activeDonor')
        })
        allDonors[i].addEventListener('mouseleave', (e) => {
          e.target.classList.remove('activeDonor')
        })
      }
    }
    scatterDonors()
    // Switch SVG preserveAspectRatio on window resize
    window.addEventListener('resize', function() {
      milkyWay.setAttribute('preserveAspectRatio', (window.innerWidth < 800) ? 'xMidYMin meet' : 'xMidYMin slice')
      scatterDonors()
    })
    
    // Flash 3 star popups 
    let controller = new ScrollMagic.Controller()
    new ScrollMagic.Scene({
      triggerElement: '#donorsFlashDonorsTrigger',
      triggerHook: 0.5,
      duration: 10,
      offset: 0,
    })
    .on('start', function() {
      console.log('start flasht rigger')
    })
    .addTo(controller)

  })

  return (
      <StaticQuery 
        query={graphql`
          query partnerQuery {
            allWordpressPage(filter: {slug: {eq: "home"}}) {
              edges {
                node {
                  acf {
                    partners_title
                    partners_blurb
                    partners_button {
                      title
                      url
                      target
                    }
                  }
                }
              }
            }
            allWordpressWpPartners {
              edges {
                node {
                  title
                  categories {
                    name
                  }
                }
              }
              }
            }
          `}
        render={data => {
          return (
          <div className={styles.partners}>
            <div className={styles.spacer} />
            <svg 
              id="donationPathSVG"
              className={styles.donationPathSVG}
              viewBox="0 0 1500 1356"
              preserveAspectRatio="xMidYMin slice">
              <path 
                ref={donorPath} 
                style={{fill: 'none', stroke: 'none', strokeMiterlimit: '10'}} 
                d="M2,1017c749,0,749-677,1498-677"/>
              <radialGradient 
                id="radGrad1"
                cx="775.5468"
                cy="149.9905"
                r="814.6725"
                fx="1584.434"
                fy="246.9077"
                gradientTransform="matrix(-0.2146 -0.3967 0.9032 -0.3771 774.9958 813.6497)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{stopColor: '#FFFFFF', stopOpacity: '0.1506'}}/>
                <stop offset="0.5161" style={{stopColor: '#FFFFFF'}}/>
                <stop offset="1" style={{stopColor: '#FFFFFF', stopOpacity:'0'}}/>
              </radialGradient>
              <path
                id="milkyWay1"
                style={{opacity:'.04', fill: 'url(#radGrad1)'}}
                d="M0,622c495.2,0,787.2-550.5,1500-550.5c0,144.7,0,611.6,0,764.8
                C1063.2,835.4,583.3,1351,0,1351C0,1207.6,0,717.9,0,622z"
              />
              <radialGradient
                id="radGrad2"
                cx="795.5252"
                cy="729.1871"
                r="780.1836"
                gradientTransform="matrix(-0.2831 -0.3621 0.9067 -0.5472 318.0684 1390.8485)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" style={{stopColor:'#FFFFFF'}}/>
                <stop offset="1" style={{stopColor: '#FFFFFF', stopOpacity: '0'}}/>
              </radialGradient>
              <path 
                id="milkyWay2"
                style={{opacity:'.06', fill: 'url(#radGrad2)'}} 
                d="M0,925.7c495.2,0,787.2-702.4,1500-702.4c0,144.7,0,364,0,517.2
                c-436.8-0.8-916.7,615.8-1500,615.8C0,1213.1,0,1021.7,0,925.7z"
              />
            </svg>
            {
            data.allWordpressWpPartners.edges.map((donor, i) => (
              <Donors key={`donor-${i}`} title={donor.node.title} tier={(donor.node.categories != null) && donor.node.categories[0].name } right={ ( i > (data.allWordpressWpPartners.edges.length / 2)) ? true : false } />
              ))
            }
            <div className={styles.textBlock}>
                <h2 id="donorsFlashDonorsTrigger" dangerouslySetInnerHTML={{ __html: data.allWordpressPage.edges[0].node.acf.partners_title }} />
                <p>{ data.allWordpressPage.edges[0].node.acf.partners_blurb }</p>
                <a className={styles.heroButtonRight} href={data.allWordpressPage.edges[0].node.acf.partners_button.url} target={ data.allWordpressPage.edges[0].node.acf.partners_button.target}>{data.allWordpressPage.edges[0].node.acf.partners_button.title}</a>
            </div>
          </div>
          )
        }}
      />
  )
}

export default Partners