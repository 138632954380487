import React, { Component } from 'react'
import styles from './starfield.module.scss'
import { gsap, TimelineMax, Linear } from 'gsap'
import { RoughEase } from 'gsap/EasePack'

gsap.registerPlugin(RoughEase, Linear)

const minStars = 60 // Mobile / tablet
const maxStars = 100 // Desktop

// Animation twinkle in delay range
const delayMin = 2
const delayMax = 12

// Animation twinkle out delay range (how long it stays its full size)
const appearMin = 0.3
const appearMax = 0.8

// Animation duration range
const durationMin = 0.6
const durationMax = 2

// Number of animations to run per star? Technically doubled for yoyo
const numAnimations = 20

// Our rando func
function random(min, max) {
  if (max == null) { max = min; min = 0; }
  if (min > max) { let tmp = min; min = max; max = tmp; }
  return min + (max - min) * Math.random()
}

// Make a star with numAnimations timelines to twinkle
function createStar(baseStar, frag, width, height) {

  // Store our easing functions for each star here
  let eases = []

  // Create different easing for each animation and push to eases[]
  for (let i = 0; i < numAnimations; i++) { // Randomize our easing
    let ease = new RoughEase({ 
        template:  Linear.easeNone, 
        strength: random(1, 3), 
        points: Math.floor(random(50, 200)), 
        taper: 'both', 
        randomize: true, 
        clamp: true
    })
    eases.push(ease)
 }

  // Clone star element and append it to document fragment
  let star = baseStar.cloneNode(true)
  frag.appendChild(star)
  
  gsap.set(star, { // Set some default properties before animating
      rotation: random(360),
      xPercent: -50,
      yPercent: -50,
      scale: 0,
      x: random(width),
      y: random(height),
  })
  
  let tl = new TimelineMax({ repeat: -1, yoyo: true }); // Create this star's timeline
  
  for (let i = 0; i < numAnimations; i++) { // Add as many animations to timeline as numAnimations calls for
      
    // Get random easings to apply
    let ease1 = eases[Math.floor(random(numAnimations))]
    let ease2 = eases[Math.floor(random(numAnimations))]
    
    let alpha = random(0.7, 1)
    let scale = random(0.15, 0.4)
    
    let appear = "+=" + random(appearMin, appearMax)
    let delay = "+=" + random(delayMin, delayMax)
    let duration1 = random(durationMin, durationMax)
    let duration2 = random(durationMin, durationMax)
    
    tl.to(star, { duration: duration1, autoAlpha: alpha, scale: scale, ease: ease1, delay: delay })
    .to(star, { duration: duration2, autoAlpha: 0, scale: 0, ease: ease2, delay: appear })
  }
      
  tl.progress(random(1))
  
  return {
      element: star,
      timeline: tl
  }
}

export class StarField extends Component {
  
  componentDidMount () {
    // const {tl} = this

    const starField = document.getElementById('starField') // Starfield
    const baseStar = document.getElementById('star') // Star

    // Match height of hero page-container
    const hero = document.getElementById('hero')
    starField.style.height = hero.offsetHeight + 'px'

    // Dimensions of Starfield
    let width = starField.offsetWidth - 15;
    let height = starField.offsetHeight

    // Use width to determine numStars a.k.a. be nice to mobile/tablet
    const numStars = (width > 960) ? maxStars : minStars

    let stars = [] // Hold all our stars before appending to starfield
    let frag = document.createDocumentFragment() // A placeholder fragment for duplicating stars
    /* Creating fragment: 
    Appending a star directly to the starfield will trigger a reflow. There are 200-600 stars depending on 
    screen size, so appending them one at a time could trigger 200-600 reflows. That does not include any additional 
    reflows or repaints that might be caused by changing the initial style for each star. By appending all the stars 
    to a document fragment, modifying them on there, and then adding it to the starfield will trigger only 1 
    reflow and repaint.*/

    for (let i = 0; i < numStars; i++) {
        stars.push(createStar(baseStar, frag, width, height))
    }

    // document.body.removeChild(baseStar)
    starField.appendChild(frag)
  } 

  render () {
    return (
      <div id="starField" className={styles.starField}>
        <div id="star" className={styles.star} />
      </div>
    )
  }
}
export default StarField