import React, { useEffect } from "react"
import styles from "./pillars.module.scss"
import { TweenMax } from "gsap";

function rando(start = 0, range = 100, suffix='') {
    return Math.floor( ( Math.random() * (range - start + 1) ) + start ) + suffix
}

const Pillars = () => {
        
    useEffect(() => {

        const thePillars = document.querySelectorAll('#pillars rect');
        for (let i = 0; i < thePillars.length; i++) {
            TweenMax.to(thePillars[i], parseInt(rando(34,56)), { 
                attr: {x: rando(0, window.innerWidth) },
                repeat: -1,
                yoyo: true,
                ease: 'linear',
                delay: 3.5,
                onComplete: function(){
                    this.vars.x = rando(0, window.innerWidth);
                    this.invalidate();
                }
            }); 
        }

    }, []);

    return (
        <svg className={styles.pillarsSVG} viewBox="0 0 2700 1080" preserveAspectRatio="none">
            <linearGradient id="pillarGradient" gradientUnits="objectBoundingBox" x1="0" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{stopColor:'#7CBFE9', stopOpacity:'0'}} />
                <stop offset="100%" style={{stopColor:'#64B0E1', stopOpacity:'0.6'}} />
            </linearGradient>
            <g id="pillars">
                <rect id="XMLID_2510_" x="2710.6" y="200" className={ `${styles.pillar} ${styles.st01}` } width="257.9" height="1133"/>
                {/* <rect id="XMLID_2509_" x="2361.1" y="300" className={ `${styles.pillar} ${styles.st01}` } width="297.9" height="1133"/> */}
                <rect id="XMLID_2508_" x="2630.1" y="0" className={ `${styles.pillar} ${styles.st01}` } width="169" height="1133"/>
                {/* <rect id="XMLID_2507_" x="2330.1" y="0" className={ `${styles.pillar} ${styles.st01}` } width="129" height="1133"/> */}
                <rect id="XMLID_2506_" x="1856.4" y="100" className={ `${styles.pillar} ${styles.st01}` } width="336.3" height="1133"/>
                {/* <rect id="XMLID_2505_" x="2005.5" y="150" className={ `${styles.pillar} ${styles.st01}` } width="148.6" height="1133"/>
                <rect id="XMLID_2504_" x="1735.5" y="200" className={ `${styles.pillar} ${styles.st01}` } width="148.6" height="1133"/> */}
                <rect id="XMLID_2503_" x="1285.5" y="140" className={ `${styles.pillar} ${styles.st01}` } width="148.6" height="1133"/>
                <rect id="XMLID_2502_" x="1165.5" y="100" className={ `${styles.pillar} ${styles.st01}` } width="148.6" height="1133"/>
                {/* <rect id="XMLID_2501_" x="1122.7" y="50" className={ `${styles.pillar} ${styles.st01}` } width="74.3" height="1133"/> */}
                <rect id="XMLID_2500_" x="796.7" y="0" className={ `${styles.pillar} ${styles.st01}` } width="285.5" height="1133"/>
                <rect id="XMLID_2499_" x="762.7" y="100" className={ `${styles.pillar} ${styles.st01}` } width="74.3" height="1133"/>
                <rect id="XMLID_2498_" x="392.7" y="50" className={ `${styles.pillar} ${styles.st01}` } width="244.3" height="1133"/>
                {/* <rect id="XMLID_2497_" x="410" y="100" className={ `${styles.pillar} ${styles.st01}` } width="135.3" height="1133"/> */}
                <rect id="XMLID_2496_" x="310" y="200" className={ `${styles.pillar} ${styles.st01}` } width="135.3" height="1133"/>
                <rect id="XMLID_2495_" x="0" y="300" className={ `${styles.pillar} ${styles.st01}` } width="135.3" height="1133"/>
                <rect id="XMLID_2494_" x="90" y="0" className={ `${styles.pillar} ${styles.st01}` } width="135.3" height="1133"/>
                {/* <rect id="XMLID_2493_" x="588.8" y="100" className={ `${styles.pillar} ${styles.st01}` } width="67.7" height="1133"/> */}
                <rect id="XMLID_2492_" x="1394.4" y="300" className={ `${styles.pillar} ${styles.st01}` } width="181.2" height="1133"/>
            </g>
        </svg>
    )

}

export default Pillars